import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import AdminList from "components/List/AdminList/AdminList";
import { useDispatch } from "store";
import {
  openCreateMeetingModal,
  openDeleteMeetingModal,
  openUpdateMeetingModal,
} from "store/slices/modal";
import { getMeetings } from "store/slices/meeting";
import { meetingClient } from "services/api/manual";
import { Meeting } from "types/meeting";

interface AdminMeetingListProps {
  rows: Meeting[];
}

export const meetingHeadCells: any = [
  {
    id: "title",
    numeric: false,
    label: "Meeting Title",
    align: "left",
  },
  {
    id: "agenda",
    numeric: false,
    label: "Agenda",
    align: "left",
  },
  {
    id: "duration",
    numeric: false,
    label: "Duration",
    align: "left",
  },
  {
    id: "uisAttendees",
    numeric: false,
    label: "Attendees",
    align: "left",
  },
  {
    id: "prodAttendees",
    numeric: false,
    label: "Production Attendees",
    align: "left",
  },
  {
    id: "meetingInfo",
    numeric: false,
    label: "Additional Information",
    align: "left",
  },
];

const AdminMeetingList = ({ rows }: AdminMeetingListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showId = useSelector((state: any) => state.show.selectedShow.showId);

  const handleRefreshMeetings = () => {
    if (showId) {
      dispatch(getMeetings(showId, navigate));
    }
  };

  return (
    <Box>
      <AdminList
        title={"Meetings"}
        categoryTitle={"Meeting"}
        subtitle="Please add and remove meetings below."
        rows={rows}
        categoryId={8}
        searchComponent={true}
        onDelete={openDeleteMeetingModal}
        onAdd={openCreateMeetingModal}
        onEdit={openUpdateMeetingModal}
        editTooltipText={"Update meeting"}
        headCells={meetingHeadCells}
        searchProp="title"
        deleteMultiple={meetingClient.deleteMultipleMeetings}
        handleRefresh={handleRefreshMeetings}
      />
    </Box>
  );
};

export default AdminMeetingList;
