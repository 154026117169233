import { nanoid } from "nanoid";
import styled from "styled-components";

import { ContentLayout } from "../../../components/Layout/MainLayout/ContentLayout";
import { images } from "../../../assets";

import { services, team, wellbeingData } from "./wellbeingData";

const SectiondHeading = styled.h2`
  color: #5d18eb;
  padding: 16px 0;
  text-align: center;
  font-weight: bold;
  font-size: 38px;

  // Responsive design
  @media (max-width: 1024px) {
    /* laptop breakpoint */
    font-size: 28px;
  }

  @media (max-width: 768px) {
    /* tablet breakpoint */
    font-size: 32px;
  }

  @media (max-width: 480px) {
    /* phone breakpoint */
    font-size: 26px;
  }
`;

const SectionSubheading = styled.h3`
  padding-top: 10px;
  font-weight: 300; /* font-thin in Tailwind */
  text-align: center;
  font-size: 26px;

  // Responsive design
  @media (max-width: 1024px) {
    /* laptop breakpoint */
    font-size: 26px;
  }

  @media (max-width: 768px) {
    /* tablet breakpoint */
    font-size: 26px;
  }

  @media (max-width: 480px) {
    /* phone breakpoint */
    font-size: 22px;
  }
`;

const ListSection = styled.div`
  padding: 50px 0;
`;

const ImageContainer = styled.div`
  padding: 50px 0;
`;

export const Wellbeing = () => {
  const handleClick = () => {
    fetch("/pdf/Respect-in-The-Workplace.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("target", `_blank`);
        link.setAttribute("rel", `noreferrer`);
        const addedLink = document.body.appendChild(link);
        link.click();
        document.body.removeChild(addedLink);
      });
    });
  };

  return (
    <ContentLayout className="laptop:px-[200px] flex flex-col items-center tablet:px-[150px] phone:px-[20px] phone:">
      <section>
        <div>
          <div className="flex justify-between py-[50px] tablet:text-[18px] phone:text-[14px]">
            <div className="">
              <h1 className="">INTERNATIONAL PRODUCTION HR</h1>
            </div>
            <div>
              <h1 className="">AUGUST {new Date().getFullYear()}</h1>
            </div>
          </div>
          <div className="flex flex-col justify-between items-center">
            <div className="pt-[100px]">
              <h1 className="laptop:text-[72px] tablet:text-[54px] phone:text-[42px] phone:text-center leading-tight font-bold uppercase">
                Production Crew Support and Wellbeing Pack
              </h1>
            </div>
            <div className="w-[600px]">
              <img
                className="w-[100%]"
                src={images.wellbeingImages.image1}
                alt="wellbeing"
              />
            </div>
          </div>
        </div>
        <img
          className="w-[100%]"
          src={images.wellbeingImages.image2}
          alt="universal studios logo"
        />
      </section>

      <section className="pt-[50px]">
        <SectiondHeading>
          This pack contains details on different resources which are available
          to you as a crew member on a Universal production.
        </SectiondHeading>
        <SectionSubheading>
          If you have any questions or concerns please feel free to reach out to
          your HR representative.
        </SectionSubheading>
        <ListSection>
          <h2 className="text-[#5d18eb] text-[24px] font-bold  p-[10px]">
            Contents:
          </h2>
          {wellbeingData.sections.map((item, index) => {
            return (
              <div key={nanoid()} className="flex pt-[10px] tablet:text-[22px]">
                <p className="">{`${index + 1}.`}</p>
                <a
                  className="hover:text-[#5d18eb] ml-2"
                  href={item.link}
                >{`${item.sectionTitle}`}</a>
              </div>
            );
          })}
        </ListSection>
      </section>

      <section id="section1" className="pt-[50px]">
        <div className="border-b-2 border-[#5d18eb] py-[20px] my-[20px]">
          <SectiondHeading>
            {wellbeingData.sections[0].sectionTitle}
          </SectiondHeading>
        </div>
        <ListSection>
          <ul className="text-[20px]">
            {team.map((member) => (
              <li className="pb-2">{`• ${member.details}`}</li>
            ))}
          </ul>
          <p className="pt-[16px] text-[20px]">
            Please reach out to HR for more details on how the EAP is set up for
            your specific production.
          </p>
        </ListSection>
        <div className="py-[50px] flex justify-center">
          <img
            className="w-[80%]"
            src={images.wellbeingImages.image14}
            alt="wellbeing"
          />
        </div>
      </section>

      <section id="section2" className="pt-[50px] w-full">
        <div className="border-b-2 border-[#5d18eb] py-[20px] my-[20px]">
          <SectiondHeading>
            {wellbeingData.sections[1].sectionTitle}
          </SectiondHeading>
        </div>
        <SectionSubheading>
          We have a range of options to support crew with their wellbeing needs:
        </SectionSubheading>
        <ListSection>
          <ul className="text-[20px]">
            {services.map((member) => (
              <li className="pb-2">{`• ${member.details}`}</li>
            ))}
          </ul>
        </ListSection>
      </section>

      <section id="section3" className="pt-[50px]">
        <div className="border-b-2 border-[#5d18eb] py-[20px] my-[20px]">
          <SectiondHeading>
            {wellbeingData.sections[2].sectionTitle}
          </SectiondHeading>
        </div>
        <SectionSubheading>
          {`One of our core values at NBCUniversal is doing the right thing and treating people the right way. Our Respect in the Workplace policy is a good place to start if you need a refresh on the behavior we expect on our productions. Please speak to you Head of Department if anything in the policy is unclear.`}
        </SectionSubheading>
        <ImageContainer>
          <img
            className="m-auto w-[80%]"
            src={images.wellbeingImages.image11}
            alt=""
          />
        </ImageContainer>
        <div className="flex justify-center pt-[100px]">
          <button
            className="text-[#5d18eb] shadow-lg text-[26px] p-4 border-2 border-[#5d18eb] rounded-3xl bg-[#fff] hover:bg-[#5d18eb] hover:text-[#fff]"
            onClick={handleClick}
          >
            Respect In The Workplace Policy
          </button>
        </div>
      </section>

      <section id="section4" className="pt-[50px] w-full">
        <div className="border-b-2 border-[#5d18eb] py-[20px] my-[20px]">
          <SectiondHeading>
            {wellbeingData.sections[3].sectionTitle}
          </SectiondHeading>
        </div>
        <SectionSubheading>
          NBCUniversal is committed to creating an environment where you feel
          comfortable speaking up. If you believe you have seen, heard or
          experienced a violation of company policy, or anything that makes you
          feel uncomfortable, you should raise the issue as soon as possible
          with your immediate supervisor, a department head, a producer or
          production executive or HR.
        </SectionSubheading>
        <SectionSubheading>
          You are encouraged to escalate any concerns you have to your relevant
          production leaders, or HR, as soon as possible.
        </SectionSubheading>
        <SectionSubheading>
          If you’re not comfortable raising it via the above channels, and
          prefer to remain anonymous, then you can contact Comcast NBCUniversal
          Listens (
          <a
            className="text-[#5d18eb]"
            target="_blank"
            rel="noreferrer"
            href="http://www.comcastlistens.com/"
          >
            www.ComcastListens.com
          </a>
          ). Full details are outlined in the next section.
        </SectionSubheading>
      </section>

      <section id="section5" className="pt-[50px] text-center">
        <div className="border-b-2 border-[#5d18eb] py-[20px] my-[20px]">
          <SectiondHeading>
            {wellbeingData.sections[4].sectionTitle}
          </SectiondHeading>
        </div>
        <SectionSubheading>
          As a crew member you also have the option to raise a concern through
          our COMCAST Listens helpline.
        </SectionSubheading>
        <p className="laptop:text-[22px] tablet:text-[22px] phone:text-[16px] py-6">
          The helpline is managed by an independent third- party company. In the
          US and as allowed by law in certain other countries, you may remain
          anonymous when using the Helpline or Web Portal. However, whenever
          possible we encourage you to identify yourself so that we can more
          easily follow- up with questions and/or provide feedback to you. A
          concern received through the Comcast NBCUniversal Listens Helpline is
          promptly logged and acknowledged. When appropriate, a trained
          investigator will investigate the report.
        </p>
        <ImageContainer>
          <img
            className="mx-auto"
            src={images.wellbeingImages.image13}
            alt=""
          />
        </ImageContainer>

        <h2 className="laptop:text-[32px]  phone:text-[42px]">
          Calls from the UK:
          <span className="text-[#5d18eb] pl-3">(+44) 0808-234-9777</span>
        </h2>
        <h2 className="laptop:text-[28px]  phone:text-[42px]">
          For more information or to submit an online report visit:
          <a
            className="text-[#5d18eb] pl-3"
            target="_blank"
            rel="noreferrer"
            href="http://www.comcastnbcuniversallistens.com/"
          >
            www.ComcastNBCUniversalListens.com
          </a>
        </h2>
        <p className="text-[#5d18eb] pt-[150px] laptop:text-[42px] tablet:text-[42px] phone:text-[38px]">
          What to Expect if you Speak Up?
        </p>
        <div className="laptop:p-[50px] tablet:p-[50px] phone:py-[50px] laptop:text-[22px] tablet:text-[22px] phone:text-[16px]">
          <h2 className="text-[#000] pb-[20px] text-[32px] font-bold">{`Concerned about speaking up? Don't be.`}</h2>
          <p className="pb-[30px]">
            Speaking up is always the right thing to do, and if you choose to
            speak up, know that:
          </p>
          <p className="pb-[30px]">
            Comcast NBCUniversal is committed to responding to your concern.
            When appropriate, a trained investigator will be assigned to review
            your concern.
          </p>
          <p className="pb-[30px]">
            Confidential information—including your identity (if disclosed) and
            the information you provide—will be shared to the extent necessary
            for purposes of investigating or resolving your concern.
          </p>
          <p className="pb-[30px]">
            {`Although the investigation's results may not be shared with you, if
            wrongdoing is confirmed, appropriate discipline and/or corrective
            action will be taken regardless of the position of the individuals
            involved.`}
          </p>
          <p className="pb-[30px]">
            Comcast NBCUniversal strictly prohibits retaliation for a good faith
            report—even if your report is eventually unsubstantiated. Comcast
            NBCUniversal also prohibits retaliation when you participate in good
            faith in the handling or investigation of a report.
          </p>
          <p className="pb-[30px]">
            Refer to the Code of Conduct and your business unit’s Employee
            Handbook, as applicable, for additional information.
          </p>
        </div>
      </section>
    </ContentLayout>
  );
};
