import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import AdminList from "components/List/AdminList/AdminList";
import { useDispatch } from "store";
import {
  openCreateTemplateMeetingModal,
  openDeleteTemplateMeetingModal,
  openUpdateTemplateMeetingModal,
} from "store/slices/modal";
import { Meeting } from "types/meeting";
import { getTemplateMeetings } from "store/slices/templates";
import { TemplateState } from "types/template";
import { ProductionCompanyState } from "types/productionCompany";

interface AdminMeetingListProps {
  rows: Meeting[];
}

export const meetingHeadCells: any = [
  {
    id: "title",
    numeric: false,
    label: "Meeting Title",
    align: "left",
  },
  {
    id: "agenda",
    numeric: false,
    label: "Agenda",
    align: "left",
  },
  {
    id: "duration",
    numeric: false,
    label: "Duration",
    align: "left",
  },
  {
    id: "uisAttendees",
    numeric: false,
    label: "Attendees",
    align: "left",
  },
  {
    id: "prodAttendees",
    numeric: false,
    label: "Production Attendees",
    align: "left",
  },
  {
    id: "meetingInfo",
    numeric: false,
    label: "Additional Information",
    align: "left",
  },
];

const AdminTemplateMeetingList = ({ rows }: AdminMeetingListProps) => {
  const dispatch = useDispatch();

  const productionCompanyId = useSelector(
    (state: ProductionCompanyState) =>
      state.productionCompany.selectedProductionCompany?.productionCompanyId
  );

  console.log({ productionCompanyId });

  const selectedTemplate = useSelector(
    (state: TemplateState) => state.templates.selectedTemplate
  );

  useEffect(() => {
    if (selectedTemplate) {
      dispatch(getTemplateMeetings(productionCompanyId!, selectedTemplate.id));
    }
  }, [dispatch, selectedTemplate, productionCompanyId]);

  const handleRefreshMeetings = () => {
    if (selectedTemplate) {
      dispatch(getTemplateMeetings(productionCompanyId!, selectedTemplate.id));
    }
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <AdminList
        title={"Template meetings"}
        categoryTitle={"Meeting"}
        subtitle="Please add and remove meetings below."
        rows={rows}
        editIcon={true}
        deleteIcon={true}
        searchComponent={true}
        disableDeleteAll
        onDelete={openDeleteTemplateMeetingModal}
        onAdd={openCreateTemplateMeetingModal}
        onEdit={openUpdateTemplateMeetingModal}
        editTooltipText={"Update meeting"}
        headCells={meetingHeadCells}
        searchProp="title"
        deleteMultiple={{}}
        handleRefresh={handleRefreshMeetings}
      />
    </Box>
  );
};

export default AdminTemplateMeetingList;
