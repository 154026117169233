import React, { forwardRef } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  CardProps,
  CardHeaderProps,
  CardContentProps,
} from "@mui/material";

import { KeyedObject, SubtitlePara } from "../../types";
import { useCustomTheme } from "../../themes";

const headerSX = {
  "& .MuiCardHeader-action": { mr: 0 },
};

export interface MainCardProps extends KeyedObject {
  border?: boolean;
  boxShadow?: boolean;
  children: React.ReactNode | string;
  style?: React.CSSProperties;
  content?: boolean;
  className?: string;
  contentClass?: string;
  contentSX?: CardContentProps["sx"];
  sx?: CardProps["sx"];
  secondary?: CardHeaderProps["action"];
  shadow?: string;
  elevation?: number;
  title?: React.ReactNode | string;
  subtitleParas?: SubtitlePara[] | [];
  headingInfoComponent?: React.ReactNode;
}

// Forward ref function should not include 'ref' in the destructured props
const MainCard = forwardRef<HTMLDivElement, MainCardProps>(
  (
    {
      border = false,
      boxShadow,
      children,
      content = true,
      contentClass = "",
      contentSX = {},
      secondary,
      shadow,
      sx = {},
      title,
      subtitleParas,
      headingInfoComponent,
      ...others
    },
    ref
  ) => {
    const theme = useCustomTheme();

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? `1px solid` : "none",
          borderColor: theme.palette.tertiary.main,
          boxShadow: boxShadow ? shadow || 1 : "none",
          ...sx,
        }}
        data-testid="main-card"
      >
        {title && (
          <CardHeader
            sx={headerSX}
            title={
              <Typography gutterBottom variant="h3">
                {title}
              </Typography>
            }
            subheader={
              subtitleParas &&
              subtitleParas!.map((para: any, index: number) => (
                <Typography key={index} sx={{ pt: "16px", fontSize: "14px" }}>
                  {para.text}
                </Typography>
              ))
            }
            action={secondary}
          />
        )}

        {headingInfoComponent}

        {title && <Divider />}

        {content ? (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        ) : (
          children
        )}
      </Card>
    );
  }
);

MainCard.displayName = "MainCard";

export default MainCard;
