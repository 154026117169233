import * as React from "react";
import { Link } from "react-router-dom";

import { Logo } from "../Logo";
import { images } from "../../assets";

export const Footer = () => {
  return (
    <footer
      className={
        "h-[110px] bg-black w-full grid grid-cols-3 bottom-0 laptop:px-[150px] phone:px-[20px]"
      }
    >
      <div className="flex justify-start items-center text-[#7f8385] laptop:text-[14px] tablet:text-[14px] phone:text-[10px]">
        <p>
          Site and contents copyright © {new Date().getFullYear()} NBCUniversal
        </p>
      </div>
      <div className="flex justify-center items-center">
        <Logo source={images.logoWhite.sm} height="80" />
      </div>
      <div className="flex justify-end items-center">
        <div className="flex items-center space-x-3">
          <a
            className="text-[#7f8385] hover:text-[#8224e3] laptop:text-[14px] tablet:text-[14px] phone:text-[10px]"
            href="https://www.nbcuniversal.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>

          <div className="h-4 border-l border-[#7f8385]"></div>

          <Link
            className="text-[#7f8385] hover:text-[#8224e3] laptop:text-[14px] tablet:text-[14px] phone:text-[10px]"
            to="/terms"
          >
            Terms and Conditions
          </Link>
        </div>
      </div>
    </footer>
  );
};
